import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'forsil-info',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'complete-profile',
    loadChildren: () => import('./complete-profile/complete-profile.module').then( m => m.CompleteProfilePageModule)
  },
  {
    path: 'matrimony-profile',
    loadChildren: () => import('./matrimony-profile/matrimony-profile.module').then( m => m.MatrimonyProfilePageModule)
  },
  {
    path: 'matrimonialpopup',
    loadChildren: () => import('./matrimonialpopup/matrimonialpopup.module').then( m => m.MatrimonialpopupPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'directory',
    loadChildren: () => import('./directory/directory.module').then( m => m.DirectoryPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then( m => m.EventsPageModule)
  },
  {
    path: 'upcoming-event',
    loadChildren: () => import('./upcoming-event/upcoming-event.module').then( m => m.UpcomingEventPageModule)
  },
  {
    path: 'past-event',
    loadChildren: () => import('./past-event/past-event.module').then( m => m.PastEventPageModule)
  },
  {
    path: 'blood-donation',
    loadChildren: () => import('./blood-donation/blood-donation.module').then( m => m.BloodDonationPageModule)
  },
  {
    path: 'blood-doners',
    loadChildren: () => import('./blood-doners/blood-doners.module').then( m => m.BloodDonersPageModule)
  },
  {
    path: 'matrimony',
    loadChildren: () => import('./matrimony/matrimony.module').then( m => m.MatrimonyPageModule)
  },
  {
    path: 'set-preference',
    loadChildren: () => import('./set-preference/set-preference.module').then( m => m.SetPreferencePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'near-you',
    loadChildren: () => import('./near-you/near-you.module').then( m => m.NearYouPageModule)
  },
  {
    path: 'blogs',
    loadChildren: () => import('./blogs/blogs.module').then( m => m.BlogsPageModule)
  },
  {
    path: 'blog-post',
    loadChildren: () => import('./blog-post/blog-post.module').then( m => m.BlogPostPageModule)
  },
  {
    path: 'feed-posts',
    loadChildren: () => import('./feed-posts/feed-posts.module').then( m => m.FeedPostsPageModule)
  },
  {
    path: 'create-post',
    loadChildren: () => import('./create-post/create-post.module').then( m => m.CreatePostPageModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./chats/chats.module').then( m => m.ChatsPageModule)
  },
  {
    path: 'conversation',
    loadChildren: () => import('./conversation/conversation.module').then( m => m.ConversationPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'add-family-member',
    loadChildren: () => import('./add-family-member/add-family-member.module').then( m => m.AddFamilyMemberPageModule)
  },
  {
    path: 'select-relation',
    loadChildren: () => import('./select-relation/select-relation.module').then( m => m.SelectRelationPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'forsil-info',
    loadChildren: () => import('./community-info/community-info.module').then( m => m.CommunityInfoPageModule)
  },
  {
    path: 'matrimony-about',
    loadChildren: () => import('./matrimony-about/matrimony-about.module').then( m => m.MatrimonyAboutPageModule)
  },
  {
    path: 'select-language',
    loadChildren: () => import('./select-language/select-language.module').then( m => m.SelectLanguagePageModule)
  },
  {
    path: 'buyappalert',
    loadChildren: () => import('./buyappalert/buyappalert.module').then( m => m.BuyappalertPageModule)
  },
  {
    path: 'vt-popup',
    loadChildren: () => import('./vt-popup/vt-popup.module').then( m => m.VtPopupPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
