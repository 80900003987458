import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BuyappalertPage } from './buyappalert.page';

const routes: Routes = [
  {
    path: '',
    component: BuyappalertPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BuyappalertPageRoutingModule {}
